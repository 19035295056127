import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { type LinkBoxItem } from '@xing-com/crate-jobs-domain-serps-constants';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';
import { Link } from '@xing-com/link';

export const SkillsFormattedMessage = (
  jobRole: string,
  skills: Array<LinkBoxItem>
): React.ReactNode => {
  switch (skills.length) {
    case 0:
      return;
    case 1:
      return (
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_FAQ_TOP_SKILL_ANSWER"
          values={{
            jobRole,
            firstSkill: (
              <Link
                to={`/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${skills[0].seoName}`}
              >
                {skills[0].name}
              </Link>
            ),
          }}
        />
      );
    case 2:
      return (
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_FAQ_TOP_TWO_SKILLS_ANSWER"
          values={{
            jobRole,
            firstSkill: (
              <Link
                to={`/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${skills[0].seoName}`}
              >
                {skills[0].name}
              </Link>
            ),
            secondSkill: (
              <Link
                to={`/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${skills[1].seoName}`}
              >
                {skills[1].name}
              </Link>
            ),
          }}
        />
      );
    default:
      return (
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_FAQ_TOP_THREE_SKILLS_ANSWER"
          values={{
            jobRole,
            firstSkill: (
              <Link
                to={`/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${skills[0].seoName}`}
              >
                {skills[0].name}
              </Link>
            ),
            secondSkill: (
              <Link
                to={`/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${skills[1].seoName}`}
              >
                {skills[1].name}
              </Link>
            ),
            thirdSkill: (
              <Link
                to={`/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${skills[2].seoName}`}
              >
                {skills[2].name}
              </Link>
            ),
          }}
        />
      );
  }
};
