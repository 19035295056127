import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { type ListOfCitiesItem } from '@xing-com/crate-jobs-domain-serps-constants';
import { Link } from '@xing-com/link';

export const CitiesFormattedMessage = (
  jobRole: string,
  cities: Array<ListOfCitiesItem>
): React.ReactNode => {
  switch (cities.length) {
    case 0:
      return;
    case 1:
      return (
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_FAQ_TOP_CITY_ANSWER"
          values={{
            jobRole,
            firstCity: <Link to={cities[0].url}>{cities[0].city}</Link>,
          }}
        />
      );
    case 2:
      return (
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_FAQ_TOP_TWO_CITIES_ANSWER"
          values={{
            jobRole,
            firstCity: <Link to={cities[0].url}>{cities[0].city}</Link>,
            secondCity: <Link to={cities[1].url}>{cities[1].city}</Link>,
          }}
        />
      );
    default:
      return (
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_FAQ_TOP_THREE_CITIES_ANSWER"
          values={{
            jobRole,
            firstCity: <Link to={cities[0].url}>{cities[0].city}</Link>,
            secondCity: <Link to={cities[1].url}>{cities[1].city}</Link>,
            thirdCity: <Link to={cities[2].url}>{cities[2].city}</Link>,
          }}
        />
      );
  }
};
