import { ROLE, SKILL } from '@xing-com/crate-jobs-domain-serps-constants';

import { useSerpsMatch } from './use-serps-match';

const ALLOWED_LINKBOXES: Record<string, string[]> = {
  [SKILL]: [
    'XJM_SEO_RELATED_JOB_TITLES_BY_SKILL',
    'XJM_SEO_TOP_LOCATIONS_BY_SKILL',
  ],
};

const BLOCKED_LINKBOXES: Record<string, string[]> = {
  [ROLE]: ['XJM_SEO_TOP_CITIES_TITLE'],
};

export const useCanDisplayLinkbox = (headerCopyKey: string): boolean => {
  const serpsMatch = useSerpsMatch();
  const category = serpsMatch?.category || '';
  const subcategory = serpsMatch?.subcategory || '';

  const linkboxKey = category + (subcategory ? `-${subcategory}` : '');

  const linkboxAllowFilter = ALLOWED_LINKBOXES[linkboxKey];
  const isAllowed = linkboxAllowFilter
    ? linkboxAllowFilter.includes(headerCopyKey)
    : true;

  const linkboxBlockFilter = BLOCKED_LINKBOXES[linkboxKey];
  const isBlocked = linkboxBlockFilter
    ? linkboxBlockFilter.includes(headerCopyKey)
    : false;

  return isAllowed && !isBlocked;
};
