import { FormattedMessage } from 'react-intl';

import {
  ANY,
  CITY,
  DISCIPLINE,
  EMPLOYMENT_TYPE,
  ROLE,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type {
  SerpsCategory,
  SerpsCategoryDataField,
  SerpsCategoryName,
  SerpsLink,
  SerpsListItem,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type {
  TopCategoriesQuery,
  SerpsRoleAggregationFragment,
  SerpsCityAggregationFragment,
  SerpsDisciplineAggregationFragment,
  SerpsEmploymentTypeAggregationFragment,
} from '@xing-com/crate-jobs-domain-serps-graphql';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';

import {
  deslugify,
  disciplineIdToCanonicalSlug,
  disciplineIdToLocalizedKey,
  disciplineSlugToLocalizedKey,
  employmentTypeIdToCanonicalSlug,
  employmentTypeIdToLocalizedKey,
  employmentTypeSlugToLocalizedKey,
  slugify,
} from './parse-static-serps-data';

type CategoryAggregation =
  | SerpsCityAggregationFragment
  | SerpsDisciplineAggregationFragment
  | SerpsEmploymentTypeAggregationFragment
  | SerpsRoleAggregationFragment;

type GenerateCategoryListItemsParams = {
  data?: TopCategoriesQuery;
  dataField: SerpsCategoryDataField;
  categoryName: SerpsCategoryName;
  parentCategory?: SerpsCategory;
};

const generateMultiPartLink = (
  category: SerpsCategory,
  parentCategory: SerpsCategory
): SerpsLink | null => {
  if (!category.value) return null;

  const isTemporaryJob = category.value.includes('TEMPORARY');

  switch (`${parentCategory.name}-${category.name}`) {
    case `${DISCIPLINE}-${ROLE}`:
      return {
        url: `/jobs/${SERP_ROUTES_IDENTIFIERS.discipline}-${
          parentCategory.value
        }/${SERP_ROUTES_IDENTIFIERS.role}-${slugify(category.value)}`,
        label: (
          <>
            {category.value}
            <FormattedMessage
              id="XJM_TEASER_LINKS_JOBS_IN"
              values={{ strong: (chunks) => chunks }}
            />{' '}
            <FormattedMessage
              id={disciplineSlugToLocalizedKey(parentCategory.value)}
            />
          </>
        ),
      };
    case `${CITY}-${EMPLOYMENT_TYPE}`: {
      let employmentTypeTranslationKey = employmentTypeIdToLocalizedKey(
        category.value
      );

      if (category.value.includes('CONTRACTOR')) {
        employmentTypeTranslationKey = 'EMPLOYMENT_TYPE_CONTRACTOR_LOCAL_SERPS';
      }
      if (category.value.includes('TEMPORARY')) {
        employmentTypeTranslationKey = 'EMPLOYMENT_TYPE_TEMPORARY_LOCAL_SERPS';
      }

      return {
        url: `/jobs/${
          SERP_ROUTES_IDENTIFIERS.employmentType
        }-${employmentTypeIdToCanonicalSlug(category.value)}/${
          SERP_ROUTES_IDENTIFIERS.city
        }-${parentCategory.value}`,
        label: (
          <>
            <FormattedMessage id={employmentTypeTranslationKey} />
            <FormattedMessage
              id="XJM_TEASER_LINKS_JOBS_IN"
              values={{ strong: (chunks) => chunks }}
            />{' '}
            {deslugify(parentCategory.value)}
          </>
        ),
      };
    }
    case `${DISCIPLINE}-${EMPLOYMENT_TYPE}`:
      return {
        url: `/jobs/${
          SERP_ROUTES_IDENTIFIERS.employmentType
        }-${employmentTypeIdToCanonicalSlug(category.value)}/${
          SERP_ROUTES_IDENTIFIERS.discipline
        }-${parentCategory.value}`,
        label: (
          <>
            <FormattedMessage
              id={employmentTypeIdToLocalizedKey(category.value)}
            />
            <FormattedMessage
              id="XJM_TEASER_LINKS_JOBS_IN"
              values={{ strong: (chunks) => chunks }}
            />{' '}
            <FormattedMessage
              id={disciplineSlugToLocalizedKey(parentCategory.value)}
            />
          </>
        ),
      };
    case `${ROLE}-${EMPLOYMENT_TYPE}`:
      return {
        url: `/jobs/${
          SERP_ROUTES_IDENTIFIERS.employmentType
        }-${employmentTypeIdToCanonicalSlug(category.value)}/${
          SERP_ROUTES_IDENTIFIERS.role
        }-${parentCategory.value}`,
        label: (
          <>
            {deslugify(parentCategory.value)}{' '}
            {isTemporaryJob ? null : (
              <FormattedMessage
                id={employmentTypeIdToLocalizedKey(category.value)}
              />
            )}
            <FormattedMessage
              id={
                isTemporaryJob
                  ? 'MP_000_RESOURCE_JOB_TYPES_TEMPORARY'
                  : 'XJM_TEASER_LINKS_JOBS'
              }
            />
          </>
        ),
      };
    case `${EMPLOYMENT_TYPE}-${ROLE}`:
      return {
        url: `/jobs/${
          SERP_ROUTES_IDENTIFIERS.employmentType
        }-${parentCategory.value}/${
          SERP_ROUTES_IDENTIFIERS.role
        }-${slugify(category.value)}`,
        label: (
          <>
            {category.value}
            <FormattedMessage
              id="XJM_TEASER_LINKS_JOBS_IN"
              values={{ strong: (chunks) => chunks }}
            />{' '}
            <FormattedMessage
              id={employmentTypeSlugToLocalizedKey(parentCategory.value)}
            />
          </>
        ),
      };
    case `${CITY}-${ROLE}`:
      return {
        url: `/jobs/${slugify(category.value)}-${
          SERP_ROUTES_IDENTIFIERS.city
        }-${parentCategory.value}`,
        label: (
          <>
            {category.value}
            <FormattedMessage
              id="XJM_TEASER_LINKS_JOBS_IN"
              values={{ strong: (chunks) => chunks }}
            />{' '}
            {deslugify(parentCategory.value)}
          </>
        ),
      };
    case `${ROLE}-${CITY}`:
      return {
        url: `/jobs/${parentCategory.value}-${
          SERP_ROUTES_IDENTIFIERS.city
        }-${slugify(category.value)}`,
        label: (
          <>
            {deslugify(parentCategory.value)}
            <FormattedMessage
              id="XJM_TEASER_LINKS_JOBS_IN"
              values={{ strong: (chunks) => chunks }}
            />{' '}
            {deslugify(category.value)}
          </>
        ),
      };
    default:
      return null;
  }
};

const generateSinglePartLink = (category: SerpsCategory): SerpsLink | null => {
  if (!category.value) return null;

  switch (category.name) {
    case CITY:
      return {
        url: `/jobs/${SERP_ROUTES_IDENTIFIERS.city}-${slugify(category.value)}`,
        label: category.value,
      };
    case DISCIPLINE:
      return {
        url: `/jobs/${
          SERP_ROUTES_IDENTIFIERS.discipline
        }-${disciplineIdToCanonicalSlug(category.value)}`,
        label: (
          <FormattedMessage id={disciplineIdToLocalizedKey(category.value)} />
        ),
      };
    case EMPLOYMENT_TYPE:
      return {
        url: `/jobs/${
          SERP_ROUTES_IDENTIFIERS.employmentType
        }-${employmentTypeIdToCanonicalSlug(category.value)}`,
        label: (
          <FormattedMessage
            id={employmentTypeIdToLocalizedKey(category.value)}
          />
        ),
      };
    case ROLE:
      return {
        url: `/jobs/${SERP_ROUTES_IDENTIFIERS.role}-${slugify(category.value)}`,
        label: category.value,
      };
    case ANY:
      return {
        url: `/jobs/${slugify(category.value)}`,
        label: category.value,
      };
    default:
      return null;
  }
};

const generateItemByCategory = (
  result: CategoryAggregation,
  category: SerpsCategory,
  parentCategory?: SerpsCategory
): SerpsListItem => ({
  id: result.id,
  count: result.count,
  link: parentCategory
    ? generateMultiPartLink(category, parentCategory)
    : generateSinglePartLink(category),
});

const generateItem = (
  result: CategoryAggregation,
  categoryName: SerpsCategoryName,
  parentCategory?: SerpsCategory
): SerpsListItem | null => {
  let typedResult;

  switch (categoryName) {
    case CITY:
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      typedResult = result as SerpsCityAggregationFragment;
      return typedResult.city
        ? generateItemByCategory(
            result,
            {
              name: categoryName,
              value: typedResult.city.localizationValue ?? '',
            },
            parentCategory
          )
        : null;
    case DISCIPLINE:
      return generateItemByCategory(
        result,
        { name: categoryName, value: result.id },
        parentCategory
      );
    case EMPLOYMENT_TYPE:
      return generateItemByCategory(
        result,
        { name: categoryName, value: result.id },
        parentCategory
      );
    case ROLE:
    case ANY:
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      typedResult = result as SerpsRoleAggregationFragment;
      return typedResult.role
        ? generateItemByCategory(
            result,
            {
              name: categoryName,
              value: typedResult.role.localizationValue ?? '',
            },
            parentCategory
          )
        : null;
    default:
      return null;
  }
};

export const generateCategoryListItems = ({
  data,
  dataField,
  categoryName,
  parentCategory,
}: GenerateCategoryListItemsParams): SerpsListItem[] => {
  const results = data?.jobSearchByQuery?.aggregations?.[dataField];

  if (!results || !results.length) {
    return [];
  }

  return results
    .map((result) =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      generateItem(result as CategoryAggregation, categoryName, parentCategory)
    )
    .filter((result): result is SerpsListItem => result !== null)
    .sort((a, b) => b.count - a.count);
};
