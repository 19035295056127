import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import serializeJavascript from 'serialize-javascript';

import { BodyCopy } from '@xing-com/typography';

import * as Styled from './seo-breadcrumb.styles';

export type SeoBreadcrumbItem = {
  title: string;
  url: string;
  isExternalUrl?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export type SeoBreadcrumbProps = { items: SeoBreadcrumbItem[] };

const Separator: React.FC = () => (
  <Styled.Separator size="small">&rsaquo;</Styled.Separator>
);

const SeoBreadCrumbItem: React.FC<{
  isLast: boolean;
  item: SeoBreadcrumbItem;
}> = ({ isLast, item }) => {
  const BodyItem = isLast ? BodyCopy : Styled.Meta;

  return (
    <Styled.TextButton size="small" to={item.url}>
      <BodyItem noMargin ellipsis size="xsmall">
        {item.icon ? (
          <item.icon
            role="img"
            width={16}
            height={16}
            aria-label={item.title}
          />
        ) : (
          <Styled.TruncatedWrapper>{item.title}</Styled.TruncatedWrapper>
        )}
        {!isLast && <Separator />}
      </BodyItem>
    </Styled.TextButton>
  );
};

export const SeoBreadcrumb: React.FC<SeoBreadcrumbProps> = ({ items }) => {
  const isLastItem = (index: number): boolean => index === items.length - 1;
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const jsonLDData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': item.url,
        name: item.title,
      },
    })),
  };

  useEffect(() => {
    const checkScrollbar = (): void => {
      if (breadcrumbRef.current) {
        if (
          breadcrumbRef.current.offsetWidth < breadcrumbRef.current.scrollWidth
        ) {
          breadcrumbRef.current.scrollLeft = breadcrumbRef.current.scrollWidth;
        }
      }
    };

    window.addEventListener('resize', checkScrollbar);
    checkScrollbar();

    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {serializeJavascript(jsonLDData)}
        </script>
      </Helmet>
      <Styled.SeoBreadcrumbList ref={breadcrumbRef}>
        {items.map((item, index) => (
          <SeoBreadCrumbItem
            key={index}
            item={item}
            isLast={isLastItem(index)}
          />
        ))}
      </Styled.SeoBreadcrumbList>
    </React.Fragment>
  );
};
