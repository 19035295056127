import { useQuery } from '@apollo/client';

import {
  CITIES_DATA_FIELD,
  CITY,
  DISCIPLINE,
  DISCIPLINES_DATA_FIELD,
  SERPS_DS_CONSUMER,
  EMPLOYMENT_TYPE,
  EMPLOYMENT_TYPES_DATA_FIELD,
  ROLE,
  ROLES_DATA_FIELD,
  SKILL,
  type SerpsListItem,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type { LinkBoxItem } from '@xing-com/crate-jobs-domain-serps-constants';
import { TopCategoriesDocument } from '@xing-com/crate-jobs-domain-serps-graphql';
import type { TopCategoriesQuery } from '@xing-com/crate-jobs-domain-serps-graphql';
import { generateCategoryListItems } from '@xing-com/crate-jobs-domain-serps-helpers';

import { useSerpsMatch } from './use-serps-match';

function normalizeLocationData(
  collection:
    | TopCategoriesQuery['jobsLinkBoxTopLocationsNearby']
    | TopCategoriesQuery['jobsLinkBoxSkillTopLocations']
): LinkBoxItem[] {
  if (!collection || !collection.length) {
    return [];
  }

  const data: LinkBoxItem[] = [];

  collection.forEach((location) => {
    if (location?.seoName && location.location?.name) {
      data.push({
        seoName: location.seoName,
        name: location.location.name,
        ...(location?.jobCount ? { count: location.jobCount } : {}),
      });
    }
  });

  return data;
}

function normalizeRelatedData(
  collection:
    | TopCategoriesQuery['jobsLinkBoxJobRolesRelatedJobRoles']
    | TopCategoriesQuery['jobsLinkBoxJobRolesRelatedSkills']
): LinkBoxItem[] {
  if (!collection || !collection.length) {
    return [];
  }

  const data: LinkBoxItem[] = [];

  collection.forEach((entry) => {
    if (entry?.seoName && entry.name) {
      data.push({
        seoName: entry.seoName,
        name: entry.name,
      });
    }
  });

  return data;
}

type UseTopCategories = {
  cities: SerpsListItem[];
  disciplines: SerpsListItem[];
  employmentTypes: SerpsListItem[];
  roles: SerpsListItem[];
  citiesNearby: LinkBoxItem[];
  relatedJobRolesByJobRole: LinkBoxItem[];
  relatedSkillsByJobRole: LinkBoxItem[];
  relatedJobRolesBySkill?: LinkBoxItem[];
  relatedJobRoleSalaryStatistics?: TopCategoriesQuery['jobsLinkBoxJobRoleSalaryEstimate'];
  topLocationsBySkill: LinkBoxItem[];
};

export const useTopCategories = (): UseTopCategories => {
  const serpMatch = useSerpsMatch();
  const city = serpMatch?.categoryValue ?? '';
  const includeLocationsNearby = serpMatch?.category === CITY;
  const isJobRoleSerp = serpMatch?.category === ROLE && !serpMatch.subcategory;
  const isSkillSerp = serpMatch?.category === SKILL && !serpMatch.subcategory;

  const { data } = useQuery(TopCategoriesDocument, {
    variables: {
      consumer: SERPS_DS_CONSUMER,
      limit: 10,
      city,
      includeLocationsNearby,
      isJobRoleSerp,
      isSkillSerp,
      relatedTo: serpMatch?.categoryValue || '',
    },
    errorPolicy: 'all',
  });

  const cities = generateCategoryListItems({
    data,
    dataField: CITIES_DATA_FIELD,
    categoryName: CITY,
  });
  const disciplines = generateCategoryListItems({
    data,
    dataField: DISCIPLINES_DATA_FIELD,
    categoryName: DISCIPLINE,
  });
  const employmentTypes = generateCategoryListItems({
    data,
    dataField: EMPLOYMENT_TYPES_DATA_FIELD,
    categoryName: EMPLOYMENT_TYPE,
  });
  const roles = generateCategoryListItems({
    data,
    dataField: ROLES_DATA_FIELD,
    categoryName: ROLE,
  });
  const citiesNearby = normalizeLocationData(
    data?.jobsLinkBoxTopLocationsNearby
  );
  const relatedJobRolesByJobRole = normalizeRelatedData(
    data?.jobsLinkBoxJobRolesRelatedJobRoles
  );
  const relatedSkillsByJobRole = normalizeRelatedData(
    data?.jobsLinkBoxJobRolesRelatedSkills
  );
  const relatedJobRolesBySkill = normalizeRelatedData(
    data?.jobsLinkBoxSkillsRelatedJobRoles
  );
  const relatedJobRoleSalaryStatistics = data?.jobsLinkBoxJobRoleSalaryEstimate;
  const topLocationsBySkill = normalizeLocationData(
    data?.jobsLinkBoxSkillTopLocations
  );

  return {
    cities,
    disciplines,
    employmentTypes,
    roles,
    citiesNearby,
    relatedJobRolesByJobRole,
    relatedSkillsByJobRole,
    relatedJobRolesBySkill,
    relatedJobRoleSalaryStatistics,
    topLocationsBySkill,
  };
};
