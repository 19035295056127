import styled from 'styled-components';

import { TextButton as InnerTextButton } from '@xing-com/button';
import { mediaTopBarHidden, mediaTopBarVisible } from '@xing-com/layout-tokens';
import { scale120, spaceL, spaceS, spaceXL } from '@xing-com/tokens';
import { Meta as InnerMeta } from '@xing-com/typography';

export const TextButton = styled(InnerTextButton)`
  height: ${scale120};
  &:last-child {
    pointer-events: none;
  }
`;

export const Separator = styled(InnerMeta)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${spaceS};
  padding: 0;
`;

export const SeoBreadcrumbList = styled.div`
  display: flex;
  margin: ${spaceL} 0 ${spaceXL} 0;
  & > * {
    flex: 0 0 auto;
  }

  @media ${mediaTopBarVisible} {
    overflow: auto;
    margin: ${spaceL} 0 ${spaceL} 0;
  }

  @media ${mediaTopBarHidden} {
    flex-wrap: wrap;
  }
`;

export const Meta = styled(InnerMeta)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TruncatedWrapper = styled.span`
  min-width: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
