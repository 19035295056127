import type React from 'react';
import styled from 'styled-components';

import { TagList } from '@xing-com/crate-jobs-domain-serps-components-tag-list';
import { useTopCategories } from '@xing-com/crate-jobs-domain-serps-hooks';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceL, spaceXL } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const Title = styled(Headline)`
  margin-bottom: ${spaceL};

  @media ${mediaConfined} {
    margin-bottom: ${spaceXL};
  }
`;

export const RelatedJobs: React.FC = () => {
  const { relatedJobRolesByJobRole } = useTopCategories();

  const tagListData =
    relatedJobRolesByJobRole.length > 0
      ? relatedJobRolesByJobRole.map((item) => ({
          name: item.name,
          seoName: item.name,
          url: `/jobs/${SERP_ROUTES_IDENTIFIERS.role}-${item.seoName}`,
        }))
      : [];

  return tagListData.length > 0 ? (
    <TagList
      headerCopyKey="XJM_JOBS_SEO_ROLE_RELATED_JOBS_HEADLINE"
      list={tagListData}
      showCount="hide"
      showEmptyResults="show"
    />
  ) : null;
};
