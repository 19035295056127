import { redirectTo, useLocation } from '@reach/router';

import {
  COMPANY,
  DISCIPLINE,
  EMPLOYMENT_TYPE,
  SERPS_PARAMS,
} from '@xing-com/crate-jobs-domain-serps-constants';
import {
  companySlugToLocalizedString,
  disciplineSlugToId,
  employmentTypeSlugToId,
  getSerpsRouteData,
} from '@xing-com/crate-jobs-domain-serps-helpers';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';

export function useSerpsMatch(): SerpsRouteParams | null {
  const { pathname: rawPathname } = useLocation();
  const pathname = decodeURIComponent(rawPathname);

  const { params, path } = getSerpsRouteData(pathname) || {};

  if (!path || !params || !(path in SERPS_PARAMS)) return null;

  const { category, subcategory } = SERPS_PARAMS[path];
  const { categoryValue, subcategoryValue } = params;

  const isUnknownDisciplineCategory = Boolean(
    category === DISCIPLINE &&
      categoryValue &&
      !disciplineSlugToId(categoryValue)
  );
  const isUnknownEmploymentCategory = Boolean(
    category === EMPLOYMENT_TYPE &&
      categoryValue &&
      !employmentTypeSlugToId(categoryValue)
  );
  const isUnknownCompany = Boolean(
    category === COMPANY &&
      categoryValue &&
      !companySlugToLocalizedString(categoryValue)
  );
  let shouldReplace =
    isUnknownDisciplineCategory ||
    isUnknownEmploymentCategory ||
    isUnknownCompany;

  if (subcategory) {
    // Safe cast, if there's subcategory, subcategoryValue will also exists as the pattern
    // will only pass if both values exist
    const isUnknownDisciplineSubcategory = Boolean(
      subcategory === DISCIPLINE &&
        subcategoryValue &&
        !disciplineSlugToId(subcategoryValue)
    );
    const isUnknownEmploymentSubcategory = Boolean(
      subcategory === EMPLOYMENT_TYPE &&
        subcategoryValue &&
        !employmentTypeSlugToId(subcategoryValue)
    );
    shouldReplace =
      shouldReplace ||
      isUnknownDisciplineSubcategory ||
      isUnknownEmploymentSubcategory;
  }

  if (shouldReplace) {
    redirectTo('/');
    return null;
  }

  return {
    category,
    categoryValue,
    ...(subcategory && { subcategory }),
    ...(subcategoryValue && { subcategoryValue }),
  };
}
