import {
  ANY,
  CITY,
  DISCIPLINE,
  EMPLOYMENT_TYPE,
  ROLE,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type { SerpsRoutesValueAsKey } from '@xing-com/crate-jobs-domain-serps-constants';
import {
  SERPS_ROUTES,
  ROUTES,
  SERP_ROUTES_IDENTIFIERS,
} from '@xing-com/crate-jobs-paths';

import type { SerpsRoutePathParams } from './get-job-serps-search-query-variables';

type LocalisedDate = { month: string; year: string };

type EmploymentTypeMetaDescription =
  | { postings?: number; employmentType: string; city?: string }
  | { term?: string; job_type: string }
  | { job_type: string };

type SeoSerpsMetaDescriptionValue =
  | { postings: number; term: string }
  | { postings: number; city: string }
  | { postings: number; city: string; role?: string }
  | { postings: number; role: string };

type SeoSerpsTitleTag =
  | (
      | ({ term: string } & LocalisedDate)
      | ({ employmentType: string; city?: string } & LocalisedDate)
      | ({ city: string; role: string } & LocalisedDate)
      | ({ role: string } & LocalisedDate)
    )
  | LocalisedDate;

export const getSeoSerpsTitleTag = (
  category: string,
  categoryValue: string,
  subcategory?: string,
  subcategoryValue?: string
): SeoSerpsTitleTag | undefined => {
  const capitalizedCategoryValue = capitalizeFirstLetter(categoryValue);
  const capitalizedSubcategoryValue = subcategoryValue
    ? capitalizeFirstLetter(subcategoryValue)
    : undefined;

  switch (category) {
    case ANY:
      return { term: capitalizedCategoryValue, ...dateToLocalizedString() };
    case EMPLOYMENT_TYPE:
      return capitalizedSubcategoryValue
        ? {
            employmentType: capitalizedCategoryValue,
            city: capitalizedSubcategoryValue,
            ...dateToLocalizedString(),
          }
        : undefined;
    case CITY:
      return dateToLocalizedString();
    case ROLE:
      if (subcategory && categoryValue) {
        return {
          role: capitalizedCategoryValue,
          city: capitalizedSubcategoryValue || '',
          ...dateToLocalizedString(),
        };
      }
      return categoryValue
        ? { role: capitalizedCategoryValue, ...dateToLocalizedString() }
        : undefined;
    default:
      return undefined;
  }
};

export const getSeoSerpsMetaDescriptionValues = (
  category: string,
  categoryValue: string,
  totalJobs: number,
  subcategory?: string,
  subcategoryValue?: string
): SeoSerpsMetaDescriptionValue | EmploymentTypeMetaDescription | undefined => {
  const capitalizedCategoryValue = capitalizeFirstLetter(categoryValue);
  const capitalizedSubcategoryValue = subcategoryValue
    ? capitalizeFirstLetter(subcategoryValue)
    : undefined;

  switch (category) {
    case ANY:
      return { postings: totalJobs, term: capitalizedCategoryValue };
    case EMPLOYMENT_TYPE:
      return getEmploymentTypeMetaDescriptions(
        capitalizedCategoryValue,
        subcategory,
        capitalizedSubcategoryValue,
        totalJobs
      );
    case CITY:
      return { postings: totalJobs, city: capitalizedCategoryValue };
    case ROLE:
      if (subcategory === CITY) {
        return {
          postings: totalJobs,
          role: capitalizedCategoryValue,
          city: capitalizedSubcategoryValue,
        };
      }
      return { postings: totalJobs, role: capitalizedCategoryValue };
    default:
      return undefined;
  }
};

const getEmploymentTypeMetaDescriptions = (
  categoryValue: string,
  subcategory?: string,
  subcategoryValue?: string,
  totalJobs?: number
): EmploymentTypeMetaDescription => {
  switch (subcategory) {
    case CITY:
      return {
        postings: totalJobs,
        employmentType: categoryValue,
        city: subcategoryValue,
      };
    case DISCIPLINE:
      return { term: subcategoryValue, job_type: categoryValue };
    case ROLE:
      return { term: subcategoryValue, job_type: categoryValue };
    default:
      return { job_type: categoryValue };
  }
};

const dateToLocalizedString = (): LocalisedDate => {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleDateString('de', { month: 'long' });
  const currentYear = currentDate.toLocaleDateString('default', {
    year: 'numeric',
  });

  return { month: currentMonth, year: currentYear };
};

export const capitalizeFirstLetter = (term: string): string =>
  term.charAt(0).toUpperCase() + term.slice(1);

export const getSerpsRouteData = (
  pathname: string
): {
  params: SerpsRoutePathParams;
  path: SerpsRoutesValueAsKey;
} | null => {
  // ignore non-SERP routes
  if (
    pathname === ROUTES.search ||
    pathname === ROUTES.myJobs ||
    pathname === ROUTES.findJobs
  ) {
    return null;
  }

  const serpsPathSegments = pathname.split('/').filter(Boolean);

  if (serpsPathSegments[0] !== 'jobs') {
    return null;
  }

  if (serpsPathSegments.length === 2) {
    // path structure example: /jobs/d-personalwesen-und-hr
    if (
      serpsPathSegments[1].startsWith(`${SERP_ROUTES_IDENTIFIERS.discipline}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.discipline}-`.length
          ),
        },
        path: SERPS_ROUTES.discipline,
      };

      // path structure example: /jobs/jobs-in-berlin
    } else if (
      serpsPathSegments[1].startsWith(`${SERP_ROUTES_IDENTIFIERS.city}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.city}-`.length
          ),
        },
        path: SERPS_ROUTES.city,
      };

      // path structure example: /jobs/k-student
    } else if (
      serpsPathSegments[1].startsWith(
        `${SERP_ROUTES_IDENTIFIERS.employmentType}-`
      )
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.employmentType}-`.length
          ),
        },
        path: SERPS_ROUTES.employmentType,
      };

      // path structure example: /jobs/t-controller
    } else if (
      serpsPathSegments[1].startsWith(`${SERP_ROUTES_IDENTIFIERS.role}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.role}-`.length
          ),
        },
        path: SERPS_ROUTES.role,
      };

      // path structure example: /jobs/c-manpower
    } else if (
      serpsPathSegments[1].startsWith(`${SERP_ROUTES_IDENTIFIERS.company}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.company}-`.length
          ),
        },
        path: SERPS_ROUTES.company,
      };

      // path structure example: /jobs/projektmanager-jobs-in-berlin
    } else if (
      serpsPathSegments[1].includes(`-${SERP_ROUTES_IDENTIFIERS.city}-`)
    ) {
      const [categoryValue, subcategoryValue] = serpsPathSegments[1].split(
        `-${SERP_ROUTES_IDENTIFIERS.city}-`
      );

      return {
        params: {
          categoryValue,
          subcategoryValue,
        },
        path: SERPS_ROUTES.roleCity,
      };
    }

    // path structure example: /jobs/controller
    return {
      params: { categoryValue: serpsPathSegments[1] },
      path: SERPS_ROUTES.any,
    };
  } else if (serpsPathSegments.length === 3) {
    if (serpsPathSegments[1] === SERP_ROUTES_IDENTIFIERS.skill) {
      // path structure example: /jobs/skill/projektleitung-jobs-in-berlin
      if (serpsPathSegments[2].includes(`-${SERP_ROUTES_IDENTIFIERS.city}-`)) {
        const [categoryValue, subcategoryValue] = serpsPathSegments[2].split(
          `-${SERP_ROUTES_IDENTIFIERS.city}-`
        );

        return {
          params: {
            categoryValue,
            subcategoryValue,
          },
          path: SERPS_ROUTES.skillCity,
        };

        // path structure example: /jobs/skill/projektleitung
      } else {
        return {
          params: {
            categoryValue: serpsPathSegments[2],
          },
          path: SERPS_ROUTES.skill,
        };
      }

      // path structure example: /jobs/d-personalwesen-und-hr/l-hamburg
    } else if (
      serpsPathSegments[1].startsWith(
        `${SERP_ROUTES_IDENTIFIERS.discipline}-`
      ) &&
      serpsPathSegments[2].startsWith(`${SERP_ROUTES_IDENTIFIERS.location}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.discipline}-`.length
          ),
          subcategoryValue: serpsPathSegments[2].slice(
            `${SERP_ROUTES_IDENTIFIERS.location}-`.length
          ),
        },
        path: SERPS_ROUTES.disciplineCity,
      };

      // path structure example: /jobs/d-personalwesen-und-hr/t-controller
    } else if (
      serpsPathSegments[1].startsWith(
        `${SERP_ROUTES_IDENTIFIERS.discipline}-`
      ) &&
      serpsPathSegments[2].startsWith(`${SERP_ROUTES_IDENTIFIERS.role}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.discipline}-`.length
          ),
          subcategoryValue: serpsPathSegments[2].slice(
            `${SERP_ROUTES_IDENTIFIERS.role}-`.length
          ),
        },
        path: SERPS_ROUTES.disciplineRole,
      };

      // path structure example: /jobs/k-teilzeit/l-berlin
    } else if (
      serpsPathSegments[1].startsWith(
        `${SERP_ROUTES_IDENTIFIERS.employmentType}-`
      ) &&
      serpsPathSegments[2].startsWith(`${SERP_ROUTES_IDENTIFIERS.location}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.employmentType}-`.length
          ),
          subcategoryValue: serpsPathSegments[2].slice(
            `${SERP_ROUTES_IDENTIFIERS.location}-`.length
          ),
        },
        path: SERPS_ROUTES.employmentTypeCity,
      };

      // path structure example: /jobs/k-teilzeit/d-personalwesen-und-hr
    } else if (
      serpsPathSegments[1].startsWith(
        `${SERP_ROUTES_IDENTIFIERS.employmentType}-`
      ) &&
      serpsPathSegments[2].startsWith(`${SERP_ROUTES_IDENTIFIERS.discipline}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.employmentType}-`.length
          ),
          subcategoryValue: serpsPathSegments[2].slice(
            `${SERP_ROUTES_IDENTIFIERS.discipline}-`.length
          ),
        },
        path: SERPS_ROUTES.employmentTypeDiscipline,
      };

      // path structure example: /jobs/k-teilzeit/t-controller
    } else if (
      serpsPathSegments[1].startsWith(
        `${SERP_ROUTES_IDENTIFIERS.employmentType}-`
      ) &&
      serpsPathSegments[2].startsWith(`${SERP_ROUTES_IDENTIFIERS.role}-`)
    ) {
      return {
        params: {
          categoryValue: serpsPathSegments[1].slice(
            `${SERP_ROUTES_IDENTIFIERS.employmentType}-`.length
          ),
          subcategoryValue: serpsPathSegments[2].slice(
            `${SERP_ROUTES_IDENTIFIERS.role}-`.length
          ),
        },
        path: SERPS_ROUTES.employmentTypeRole,
      };
    }
  }

  return null;
};
