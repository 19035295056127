import type React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { capitalizeFirstLetter } from '@xing-com/crate-jobs-domain-serps-helpers';
import {
  useTopSubcategories,
  useSerpsMatch,
  useTopCategories,
} from '@xing-com/crate-jobs-domain-serps-hooks';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  space3XL,
  space4XL,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  spaceXXL,
} from '@xing-com/tokens';
import { Headline, BodyCopy } from '@xing-com/typography';

import { CitiesFormattedMessage } from './cities-formatted-message';
import { SkillsFormattedMessage } from './skills-formatted-message';

export const Container = styled.div`
  margin-bottom: ${space4XL};
`;

export const Title = styled(Headline)`
  margin-bottom: ${spaceL};

  @media ${mediaConfined} {
    margin-bottom: ${spaceXL};
  }
`;

export const StyledCard = styled(Card)`
  padding: ${spaceXL};

  @media ${mediaConfined} {
    padding: ${spaceXXL};
  }
`;

export const CitiesQAWrapper = styled.div<{ $hasMargin: boolean }>`
  margin-bottom: ${(props) => (props.$hasMargin ? `${space3XL}` : 0)};
`;

export const Question = styled(Headline)`
  margin-bottom: ${spaceS};

  @media ${mediaConfined} {
    margin-bottom: ${spaceM};
  }
`;

export const TopCitiesSkills: React.FC = () => {
  const { formatMessage } = useIntl();
  const serps = useSerpsMatch();
  const jobRole = capitalizeFirstLetter(serps?.categoryValue || '');
  const { listOfCities } = useTopSubcategories();
  const { relatedSkillsByJobRole } = useTopCategories();
  const skillsLength = relatedSkillsByJobRole.length;

  if (!((listOfCities.length > 0 || skillsLength > 0) && jobRole)) {
    return null;
  }

  return (
    <Container>
      <Title size="large" sizeConfined="xlarge">
        {formatMessage({ id: 'XJM_JOBS_SEO_ROLE_FAQ_HEADLINE' })}
      </Title>
      <StyledCard>
        {listOfCities.length > 0 ? (
          <CitiesQAWrapper $hasMargin={skillsLength > 0}>
            <Question size="large">
              {formatMessage(
                { id: 'XJM_JOBS_SEO_ROLE_FAQ_TOP_CITY_QUESTION' },
                {
                  jobRole,
                }
              )}
            </Question>
            <BodyCopy noMargin>
              {CitiesFormattedMessage(jobRole, listOfCities)}
            </BodyCopy>
          </CitiesQAWrapper>
        ) : null}
        {skillsLength > 0 ? (
          <>
            <Question size="large">
              {formatMessage(
                { id: 'XJM_JOBS_SEO_ROLE_FAQ_TOP_SKILL_QUESTION' },
                {
                  jobRole,
                }
              )}
            </Question>
            <BodyCopy noMargin>
              {SkillsFormattedMessage(jobRole, relatedSkillsByJobRole)}
            </BodyCopy>
          </>
        ) : null}
      </StyledCard>
    </Container>
  );
};
