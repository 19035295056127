import type React from 'react';

import { TagList } from '@xing-com/crate-jobs-domain-serps-components-tag-list';
import type { SerpsListItem } from '@xing-com/crate-jobs-domain-serps-constants';
import { CITY, ROLE } from '@xing-com/crate-jobs-domain-serps-constants';
import {
  capitalizeFirstLetter,
  slugify,
} from '@xing-com/crate-jobs-domain-serps-helpers';
import {
  useTopSubcategories,
  useSerpsMatch,
} from '@xing-com/crate-jobs-domain-serps-hooks';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';

export const TopSubcategories: React.FC = () => {
  const { employmentTypes, roles, cities } = useTopSubcategories();
  const serps = useSerpsMatch();
  const isLocalRoleSerps =
    serps && (serps.category === CITY || serps.category === ROLE);

  const employmentTypesFiltered = employmentTypes.filter(
    (item) =>
      !item.id.includes('VOLUNTARY') &&
      !item.id.includes('SEASONAL') &&
      !item.id.includes('FULL_TIME')
  );

  const serpsListResults = isLocalRoleSerps
    ? employmentTypesFiltered
    : employmentTypes;
  const isLocalOnlySerp = serps?.category === CITY && !serps?.subcategory;

  let contractorEmploymentType: SerpsListItem | undefined;
  let studentEmploymentType: SerpsListItem | undefined;
  let partTimeEmploymentType: SerpsListItem | undefined;
  let temporaryEmploymentType: SerpsListItem | undefined;

  employmentTypesFiltered.forEach((item) => {
    if (item.id.includes('CONTRACTOR')) {
      contractorEmploymentType = item;
    } else if (item.id.includes('INTERN')) {
      studentEmploymentType = item;
    } else if (item.id.includes('PART_TIME')) {
      partTimeEmploymentType = item;
    } else if (item.id.includes('TEMPORARY')) {
      temporaryEmploymentType = item;
    }
  });

  if (isLocalOnlySerp) {
    if (contractorEmploymentType?.link?.url) {
      contractorEmploymentType.link.url = `/jobs/freelancer-${SERP_ROUTES_IDENTIFIERS.city}-${slugify(
        serps?.categoryValue
      ).toLocaleLowerCase()}`;
    }
    if (studentEmploymentType?.link?.url) {
      studentEmploymentType.link.url = `/jobs/${SERP_ROUTES_IDENTIFIERS.employmentType}-student/l-${slugify(
        serps?.categoryValue
      ).toLocaleLowerCase()}`;
    }
    if (partTimeEmploymentType?.link?.url) {
      partTimeEmploymentType.link.url = `/jobs/${SERP_ROUTES_IDENTIFIERS.employmentType}-teilzeit/${SERP_ROUTES_IDENTIFIERS.location}-${slugify(
        serps?.categoryValue
      ).toLocaleLowerCase()}`;
    }
    if (temporaryEmploymentType?.link?.url) {
      temporaryEmploymentType.link.url = `/jobs/minijob-${SERP_ROUTES_IDENTIFIERS.city}-${slugify(
        serps?.categoryValue
      ).toLocaleLowerCase()}`;
    }
  }

  return (
    <div>
      <TagList
        headerCopyKey="XJM_TEASER_LINKS_JOB_TYPE_TITLE"
        serpsList={serpsListResults}
      />

      <TagList headerCopyKey="XJM_TEASER_LINKS_TERM_TITLE" serpsList={roles} />

      <TagList
        headerCopyKey="XJM_SEO_TOP_JOB_ROLE_LOCATIONS"
        headerCopyValues={{
          role: capitalizeFirstLetter(serps?.categoryValue || ''),
        }}
        serpsList={cities}
      />
    </div>
  );
};
