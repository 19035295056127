import { SERPS_ROUTES } from '@xing-com/crate-jobs-paths';

export const SERPS_DS_CONSUMER = 'loggedout.web.jobs.search_results.bottom';

export const CITY = 'city';
export const DISCIPLINE = 'discipline';
export const EMPLOYMENT_TYPE = 'employmentType';
export const ROLE = 'role';
export const COMPANY = 'company';
export const SKILL = 'skill';
export const ANY = 'any';
export const SALARY = 'salary';

export const CITIES_DATA_FIELD = 'cities';
export const DISCIPLINES_DATA_FIELD = 'disciplines';
export const EMPLOYMENT_TYPES_DATA_FIELD = 'employmentTypes';
export const ROLES_DATA_FIELD = 'roles';

export type SerpsCategoryName =
  | typeof CITY
  | typeof DISCIPLINE
  | typeof EMPLOYMENT_TYPE
  | typeof ROLE
  | typeof COMPANY
  | typeof SKILL
  | typeof ANY;

export type SerpsCategoryDataField =
  | typeof CITIES_DATA_FIELD
  | typeof DISCIPLINES_DATA_FIELD
  | typeof EMPLOYMENT_TYPES_DATA_FIELD
  | typeof ROLES_DATA_FIELD;

export type SerpsCategory = {
  name: SerpsCategoryName;
  value: string;
};

export type SerpsListItem = {
  id: string;
  count: number;
  link: SerpsLink | null;
};

export type SerpsLink = {
  url: string;
  label: JSX.Element | string;
};

export type LinkBoxItem = {
  seoName: string;
  name: string;
  count?: number;
};

export type ListOfCitiesItem = {
  url: string;
  city: string;
};

export type SerpsRoutesValueAsKey =
  (typeof SERPS_ROUTES)[keyof typeof SERPS_ROUTES];

export const SERPS_PARAMS: Record<
  SerpsRoutesValueAsKey,
  { category: SerpsCategoryName; subcategory?: SerpsCategoryName }
> = {
  [SERPS_ROUTES.disciplineCity]: {
    category: DISCIPLINE,
    subcategory: CITY,
  },
  [SERPS_ROUTES.disciplineRole]: {
    category: DISCIPLINE,
    subcategory: ROLE,
  },
  [SERPS_ROUTES.employmentTypeCity]: {
    category: EMPLOYMENT_TYPE,
    subcategory: CITY,
  },
  [SERPS_ROUTES.employmentTypeRole]: {
    category: EMPLOYMENT_TYPE,
    subcategory: ROLE,
  },
  [SERPS_ROUTES.employmentTypeDiscipline]: {
    category: EMPLOYMENT_TYPE,
    subcategory: DISCIPLINE,
  },
  [SERPS_ROUTES.roleCity]: {
    category: ROLE,
    subcategory: CITY,
  },
  [SERPS_ROUTES.skillCity]: {
    category: SKILL,
    subcategory: CITY,
  },
  [SERPS_ROUTES.employmentType]: {
    category: EMPLOYMENT_TYPE,
  },
  [SERPS_ROUTES.discipline]: {
    category: DISCIPLINE,
  },
  [SERPS_ROUTES.city]: {
    category: CITY,
  },
  [SERPS_ROUTES.role]: {
    category: ROLE,
  },
  [SERPS_ROUTES.company]: {
    category: COMPANY,
  },
  [SERPS_ROUTES.skill]: {
    category: SKILL,
  },
  [SERPS_ROUTES.any]: {
    category: ANY,
  },
} as const;

export const TRANSLATIONS_MAP: Record<
  string,
  {
    title?: string;
    titles?: Record<string, string>;
    metaTitle: string;
    metaDescription: string;
    paramName: string;
    subparamName?: string;
  }
> = {
  [ANY]: {
    title: 'JOB_SEO_TERM_JOBS',
    metaTitle: 'XJM_META_TITLE_TERM_ANY_WITH_DATE',
    metaDescription: 'XJM_META_DESCRIPTION_TERM_ANY_WITH_POSTINGS',
    paramName: 'term',
  },
  [COMPANY]: {
    title: 'JOB_SEO_COMPANY_JOBS',
    metaTitle: 'XJM_META_TITLE_COMPANY',
    metaDescription: 'XJM_META_DESCRIPTION_COMPANY',
    paramName: 'term',
  },
  [SKILL]: {
    title: 'JOB_SEO_COMPANY_JOBS',
    metaTitle: 'XJM_META_TITLE_SKILL',
    metaDescription: 'XJM_META_DESCRIPTION_SKILL',
    paramName: 'term',
  },
  [CITY]: {
    title: 'JOB_SEO_CITY_JOBS',
    metaTitle: 'XJM_META_TITLE_CITY_WITH_DATE',
    metaDescription: 'XJM_META_DESCRIPTION_CITY_WITH_POSTINGS',
    paramName: 'city',
  },
  [DISCIPLINE]: {
    title: 'JOB_SEO_CATEGORY_JOBS',
    metaTitle: 'XJM_META_TITLE_CATEGORY',
    metaDescription: 'XJM_META_DESCRIPTION_CATEGORY',
    paramName: 'category',
  },
  [EMPLOYMENT_TYPE]: {
    titles: {
      EMPLOYMENT_TYPE_FULL_TIME: 'JOB_SEO_EMPLOYMENT_TYPE_FULL_TIME',
      EMPLOYMENT_TYPE_PART_TIME: 'JOB_SEO_EMPLOYMENT_TYPE_PART_TIME',
      EMPLOYMENT_TYPE_INTERN: 'JOB_SEO_EMPLOYMENT_TYPE_INTERN',
      EMPLOYMENT_TYPE_CONTRACTOR: 'JOB_SEO_EMPLOYMENT_TYPE_CONTRACTOR',
      EMPLOYMENT_TYPE_TEMPORARY: 'JOB_SEO_EMPLOYMENT_TYPE_TEMPORARY',
      EMPLOYMENT_TYPE_SEASONAL: 'JOB_SEO_EMPLOYMENT_TYPE_SEASONAL',
      EMPLOYMENT_TYPE_VOLUNTARY: 'JOB_SEO_EMPLOYMENT_TYPE_VOLUNTARY',
    },
    metaTitle: 'XJM_META_TITLE_CATEGORY',
    metaDescription: 'XJM_META_DESCRIPTION_JOB_TYPE',
    paramName: 'job_type',
  },
  [ROLE]: {
    title: 'XJM_SEO_JOB_ROLE',
    metaTitle: 'XJM_META_TITLE_TERM_WITH_DATE',
    metaDescription: 'XJM_META_DESCRIPTION_TERM_WITH_POSTINGS',
    paramName: 'term',
  },
  [`${DISCIPLINE}-${CITY}`]: {
    title: 'XJM_SEARCH_TITLE_CATEGORY_CITY',
    metaTitle: 'XJM_META_TITLE_CATEGORY_CITY',
    metaDescription: 'XJM_META_DESCRIPTION_CATEGORY_CITY',
    paramName: 'category',
    subparamName: 'city',
  },
  [`${DISCIPLINE}-${ROLE}`]: {
    title: 'XJM_SEARCH_TITLE_CATEGORY_TERM',
    metaTitle: 'XJM_META_TITLE_CATEGORY_TERM',
    metaDescription: 'XJM_META_DESCRIPTION_CATEGORY_TERM',
    paramName: 'category',
    subparamName: 'term',
  },
  [`${EMPLOYMENT_TYPE}-${CITY}`]: {
    title: 'XJM_SEARCH_TITLE_EMPLOYMENT_TYPE_CITY',
    metaTitle: 'XJM_META_TITLE_EMPLOYMENT_TYPE_CITY_WITH_DATE',
    metaDescription: 'XJM_META_DESCRIPTION_EMPLOYMENT_TYPE_CITY_WITH_POSTINGS',
    paramName: 'job_type',
    subparamName: 'city',
  },
  [`${EMPLOYMENT_TYPE}-${DISCIPLINE}`]: {
    title: 'XJM_SEARCH_TITLE_JOB_TYPE_CATEGORY',
    metaTitle: 'XJM_META_TITLE_JOB_TYPE_CATEGORY',
    metaDescription: 'XJM_META_DESCRIPTION_JOB_TYPE_CATEGORY',
    paramName: 'job_type',
    subparamName: 'category',
  },
  [`${EMPLOYMENT_TYPE}-${ROLE}`]: {
    title: 'XJM_SEARCH_TITLE_JOB_TYPE_TERM',
    metaTitle: 'XJM_META_TITLE_JOB_TYPE_TERM',
    metaDescription: 'XJM_META_DESCRIPTION_JOB_TYPE_TERM',
    paramName: 'job_type',
    subparamName: 'term',
  },
  [`${ROLE}-${CITY}`]: {
    title: 'XJM_SEARCH_TITLE_TERM_CITY_WITH_DATE',
    metaTitle: 'XJM_META_TITLE_TERM_CITY_WITH_POSTINGS',
    metaDescription: 'XJM_META_DESCRIPTION_TERM_CITY_WITH_POSTINGS',
    paramName: 'term',
    subparamName: 'city',
  },
  [`${SKILL}-${CITY}`]: {
    title: 'XJM_SEARCH_TITLE_SKILL_TYPE_CITY',
    metaTitle: 'XJM_META_TITLE_SKILL_TYPE_CITY',
    metaDescription: 'XJM_META_DESCRIPTION_SKILL_TYPE_CITY',
    paramName: 'term',
    subparamName: 'city',
  },
};
