import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { Hyperlink as HyperlinkComponent } from '@xing-com/hyperlink';
import { List, ListItem as ListComponent } from '@xing-com/list';
import { space4XL, spaceXXS, xdlColorText } from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

export const Container = styled.div`
  margin-bottom: ${space4XL};
`;

export const ListItem = styled(ListComponent)`
  padding: 0;
  margin-bottom: ${spaceXXS};
  line-height: 21px;
`;

export const Hyperlink = styled(HyperlinkComponent)`
  &,
  &:visited {
    color: ${xdlColorText};
  }
`;

export const SERVICES: {
  label: string;
  href: string;
}[] = [
  { label: 'bewerbung.com', href: 'https://bewerbung.com' },
  { label: 'lebenslauf.com', href: 'https://lebenslauf.com' },
  { label: 'praktikum-guide.com', href: 'https://praktikum-guide.com/' },
];

export const ServicesList: React.FC = () => (
  <Container>
    <Headline size="large">
      <FormattedMessage id="XJM_SEO_XING_SERVICES_TITLE" />
    </Headline>
    <List>
      {SERVICES.map(({ label, href }) => (
        <ListItem key={href}>
          {/* @ts-expect-error FIXME: SC6 */}
          <Hyperlink href={href} target="_blank" rel="noopener" external>
            {label}
          </Hyperlink>
        </ListItem>
      ))}
    </List>
  </Container>
);
