import type React from 'react';
import { useIntl } from 'react-intl';

import {
  ANY,
  TRANSLATIONS_MAP,
} from '@xing-com/crate-jobs-domain-serps-constants';
import {
  capitalizeFirstLetter,
  getCategoryTranslation,
} from '@xing-com/crate-jobs-domain-serps-helpers';
import { useSerpsMatch } from '@xing-com/crate-jobs-domain-serps-hooks';
import { SERPS_ROUTES } from '@xing-com/crate-jobs-paths';
import { SeoBreadcrumb } from '@xing-com/seo-breadcrumb';

export const Breadcrumb: React.FC = () => {
  const serps = useSerpsMatch();
  const { formatMessage } = useIntl();

  if (
    !serps?.category ||
    !serps.subcategory ||
    !serps?.categoryValue ||
    !serps?.subcategoryValue
  )
    return null;

  if (serps.category === ANY || serps.subcategory === ANY) return null;

  const { title, paramName, subparamName } =
    TRANSLATIONS_MAP[`${serps.category}-${serps.subcategory}`];

  const { category, subcategory, categoryValue, subcategoryValue } = serps;

  const categoryTranslation = capitalizeFirstLetter(
    getCategoryTranslation(formatMessage, category, categoryValue)
  );
  const subCategoryTranslation = capitalizeFirstLetter(
    getCategoryTranslation(formatMessage, subcategory, subcategoryValue)
  );

  const breadcrumbItems = [
    {
      url: '/',
      title: formatMessage({ id: 'XJM_BREADCRUMB_HOME' }),
    },
    {
      url: `${SERPS_ROUTES[serps.subcategory].replace(':categoryValue', serps.subcategoryValue)}`,
      title: subCategoryTranslation,
    },
    {
      url: `${SERPS_ROUTES[serps.category].replace(':categoryValue', serps.categoryValue)}`,
      title: categoryTranslation,
    },
    {
      url: '',
      title: formatMessage(
        {
          id: title,
        },
        {
          [paramName]: categoryTranslation,
          ...(paramName === 'job_type'
            ? { employmentType: categoryTranslation }
            : {}),
          ...(paramName === 'term' ? { role: categoryTranslation } : {}),
          ...(subparamName ? { [subparamName]: subCategoryTranslation } : {}),
        }
      ),
    },
  ];

  return <SeoBreadcrumb items={breadcrumbItems} />;
};
