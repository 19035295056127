import type React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import type { SerpsListItem } from '@xing-com/crate-jobs-domain-serps-constants';
import { useCanDisplayLinkbox } from '@xing-com/crate-jobs-domain-serps-hooks';
import { mediaConfined, mediaWideNavless } from '@xing-com/layout-tokens';
import { Link } from '@xing-com/link';
import { Tag } from '@xing-com/tag';
import {
  spaceL,
  spaceM,
  spaceXL,
  spaceXXL,
  xdlColorText,
  scale130,
  space4XL,
} from '@xing-com/tokens';
import { Headline } from '@xing-com/typography';

type ListItem = {
  name: React.ReactNode;
  seoName: string;
  count?: number;
  url?: string;
};

type TagListProps = {
  headerCopyKey: string;
  headerCopyValues?: Record<string, string>;
  list?: ListItem[];
  serpsList?: SerpsListItem[];
  showEmptyResults?: 'hide' | 'show';
  showCount?: 'hide' | 'show';
};

export const Container = styled.div`
  margin-bottom: ${space4XL};
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spaceM};
  margin-bottom: ${spaceXL};

  @media ${mediaWideNavless} {
    margin-bottom: ${spaceXXL};
  }
`;

const Title = styled(Headline)`
  margin-bottom: ${spaceL};

  @media ${mediaConfined} {
    margin-bottom: ${spaceXL};
  }
`;

const StyledLink = styled(Link)`
  border-radius: ${scale130};
  color: ${xdlColorText};
`;

function serpItemsToTagListItems(items: SerpsListItem[]): ListItem[] {
  return items
    .filter((item) => item.link)
    .map((item) => ({
      name: item.link?.label || '',
      seoName: '',
      count: item.count,
      url: item.link?.url,
    }));
}

export const TagList: React.FC<TagListProps> = ({
  headerCopyKey,
  headerCopyValues = {},
  list = [],
  serpsList = [],
  showEmptyResults = 'hide',
  showCount = 'show',
}) => {
  const canDisplay = useCanDisplayLinkbox(headerCopyKey);

  if (!canDisplay) {
    return null;
  }

  const displayCount = showCount === 'show';
  const displayEmptyResults = showEmptyResults === 'show';

  // do not mutate list, make a copy
  let listToRender =
    list.length > 0 ? [...list] : serpItemsToTagListItems(serpsList);
  listToRender = displayEmptyResults
    ? listToRender
    : listToRender.filter((item) => item.count && item.count > 0);

  if (listToRender.length <= 0) {
    return null;
  }

  const sortedList = displayCount
    ? listToRender.sort(
        (itemA, itemB) => -((itemA.count || 0) - (itemB.count || 0))
      )
    : listToRender;

  return (
    <Container>
      <Title size="xlarge">
        <FormattedMessage id={headerCopyKey} values={headerCopyValues} />
      </Title>
      <TagContainer>
        {sortedList.map((item) => {
          const url = item.url;
          const count = item.count || 0;
          const name = item.name;

          return (
            <StyledLink to={url || ''} key={`${name}-${count}`}>
              <Tag>
                {name}
                {displayCount && count > 0 ? ` (${count})` : ''}
              </Tag>
            </StyledLink>
          );
        })}
      </TagContainer>
    </Container>
  );
};
