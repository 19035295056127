import { useQuery } from '@apollo/client';
import { slugify } from 'transliteration';

import {
  ANY,
  CITIES_DATA_FIELD,
  CITY,
  COMPANY,
  DISCIPLINE,
  SERPS_DS_CONSUMER,
  EMPLOYMENT_TYPE,
  EMPLOYMENT_TYPES_DATA_FIELD,
  ROLE,
  ROLES_DATA_FIELD,
  SKILL,
  type ListOfCitiesItem,
  type SerpsListItem,
} from '@xing-com/crate-jobs-domain-serps-constants';
import {
  TopSubcategoriesDocument,
  type TopSubcategoriesQueryVariables,
} from '@xing-com/crate-jobs-domain-serps-graphql';
import {
  generateCategoryListItems,
  deslugify,
  disciplineSlugToId,
  employmentTypeSlugToId,
  type SerpsRouteParams,
} from '@xing-com/crate-jobs-domain-serps-helpers';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';

import { useSerpsMatch } from './use-serps-match';

type TopSubcategories = {
  employmentTypes: SerpsListItem[];
  roles: SerpsListItem[];
  cities: SerpsListItem[];
  listOfCities: ListOfCitiesItem[];
};

export const buildQuery = (
  category: string,
  categoryValue: string
): TopSubcategoriesQueryVariables['query'] => {
  switch (category) {
    case DISCIPLINE: {
      const id = disciplineSlugToId(categoryValue);
      return id
        ? {
            filter: {
              discipline: { id: [id] },
            },
          }
        : {};
    }
    case EMPLOYMENT_TYPE: {
      const id = employmentTypeSlugToId(categoryValue);
      return id
        ? {
            filter: {
              employmentType: { id: [id] },
            },
          }
        : {};
    }
    case CITY: {
      const text = deslugify(categoryValue);
      return text
        ? {
            location: { text },
          }
        : {};
    }
    case ROLE:
    case ANY: {
      const keywords = deslugify(categoryValue);
      return keywords
        ? {
            keywords,
          }
        : {};
    }
    default:
      return {};
  }
};

/*
Top Subcategories are fetched from the current category
We have 6 categories (Discipline, Role, EmploymentType, City, Company and Skill) and 3 subcategories (EmploymentType, Role and City).
They can be combined like:
- Skill
  - City (no linkboxes)
- Company
- Discipline
  - EmploymentType
  - Role
- Role
  - EmploymentType
- EmploymentType
  - Role
- City
  - EmploymentType
  - Role
*/
export const useTopSubcategories = (): TopSubcategories => {
  const { category, categoryValue, subcategory } =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    useSerpsMatch() as SerpsRouteParams;

  const amountToFetch = category === CITY ? 20 : 10;

  const { data } = useQuery(TopSubcategoriesDocument, {
    variables: {
      query: buildQuery(category, categoryValue),
      consumer: SERPS_DS_CONSUMER,
      limit: amountToFetch,
    },
    skip: category === COMPANY || category === SKILL,
    errorPolicy: 'all',
  });

  const parentCategory = {
    name: category,
    value: categoryValue,
  };
  const employmentTypes =
    parentCategory.name !== EMPLOYMENT_TYPE
      ? generateCategoryListItems({
          data,
          dataField: EMPLOYMENT_TYPES_DATA_FIELD,
          categoryName: EMPLOYMENT_TYPE,
          parentCategory,
        })
      : [];
  const roles =
    parentCategory.name !== ROLE
      ? generateCategoryListItems({
          data,
          dataField: ROLES_DATA_FIELD,
          categoryName: ROLE,
          parentCategory,
        })
      : [];

  const cities =
    category === ROLE && !subcategory // show jobrole + location linkboxes only in ROLE SERPS (e.g. t-programmer)
      ? generateCategoryListItems({
          data,
          dataField: CITIES_DATA_FIELD,
          categoryName: CITY,
          parentCategory,
        }).filter((listItem) => listItem.count >= 5)
      : [];

  const listOfCities: Array<ListOfCitiesItem> =
    data?.jobSearchByQuery?.aggregations?.cities?.map((item, index) => ({
      city: item?.city?.localizationValue || '',
      url:
        cities[index]?.link?.url ||
        `/jobs/${slugify(categoryValue).toLocaleLowerCase()}-${
          SERP_ROUTES_IDENTIFIERS.city
        }-${slugify(item?.city?.localizationValue || '').toLocaleLowerCase()}`,
    })) || [];

  return { employmentTypes, roles, cities, listOfCities };
};
