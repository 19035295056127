import type { IntlFormatters } from 'react-intl';

import {
  ANY,
  CITY,
  COMPANY,
  DISCIPLINE,
  EMPLOYMENT_TYPE,
  ROLE,
  SKILL,
} from '@xing-com/crate-jobs-domain-serps-constants';
import type { SerpsCategoryName } from '@xing-com/crate-jobs-domain-serps-constants';

import {
  companySlugToLocalizedString,
  deslugify,
  disciplineSlugToLocalizedKey,
  employmentTypeSlugToLocalizedKey,
  skillSlugToLocalizedString,
} from './parse-static-serps-data';

export const getCategoryTranslation = (
  formatMessage: IntlFormatters['formatMessage'],
  category: SerpsCategoryName,
  categoryValue: string
): string => {
  switch (category) {
    case CITY:
      return deslugify(categoryValue);
    case DISCIPLINE:
      return formatMessage({ id: disciplineSlugToLocalizedKey(categoryValue) });
    case EMPLOYMENT_TYPE:
      return formatMessage({
        id: employmentTypeSlugToLocalizedKey(categoryValue),
      });
    case COMPANY:
      return companySlugToLocalizedString(categoryValue);
    case SKILL:
      return skillSlugToLocalizedString(categoryValue);
    case ROLE:
    case ANY:
      return deslugify(categoryValue);
  }
};
