import type React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Card } from '@xing-com/card';
import { capitalizeFirstLetter } from '@xing-com/crate-jobs-domain-serps-helpers';
import {
  useTopCategories,
  useSerpsMatch,
} from '@xing-com/crate-jobs-domain-serps-hooks';
import { mediaConfined } from '@xing-com/layout-tokens';
import {
  scale150,
  space4XL,
  spaceL,
  spaceM,
  spaceS,
  spaceXL,
  xdlColorTextSecondary,
} from '@xing-com/tokens';
import { BodyCopy, Headline, Hero } from '@xing-com/typography';

const getFormattedNumber = (value: number): string =>
  value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const Container = styled.div`
  margin-bottom: ${space4XL};
`;

export const Title = styled(Headline)`
  margin-bottom: ${spaceL};
  @media ${mediaConfined} {
    margin-bottom: ${spaceXL};
  }
`;

export const SalaryCard = styled(Card)`
  margin-bottom: ${spaceL};
  padding: ${spaceXL};
  width: 100%;

  @media ${mediaConfined} {
    margin-bottom: ${spaceXL};
  }
`;

export const AverageSalary = styled(Hero)`
  font-weight: 700;
  margin: 0 0 ${spaceXL};
  margin-bottom: ${spaceS};
  padding: 0;
  font-size: ${scale150};
  line-height: 1.2;

  @media ${mediaConfined} {
    margin-bottom: ${spaceM};
  }
`;

export const SalaryRoleDescription = styled(BodyCopy)`
  color: ${xdlColorTextSecondary};
`;

export const SalaryData: React.FC = () => {
  const { formatMessage } = useIntl();
  const serps = useSerpsMatch();
  const { relatedJobRoleSalaryStatistics } = useTopCategories();
  const { maximum, median, minimum } = relatedJobRoleSalaryStatistics || {};
  const isStatisticsEmpty =
    !relatedJobRoleSalaryStatistics ||
    Object.keys(relatedJobRoleSalaryStatistics).length === 0;

  if (isStatisticsEmpty || median === 0) {
    return null;
  }

  return (
    <Container>
      <Title size="large" sizeConfined="xlarge">
        {formatMessage(
          { id: 'XJM_JOBS_SEO_ROLE_SALARY_HEADER' },
          { jobRole: capitalizeFirstLetter(serps?.categoryValue || '') }
        )}
      </Title>
      {median ? (
        <SalaryCard>
          <AverageSalary as="div">
            {getFormattedNumber(median || 0)} €
          </AverageSalary>
          <BodyCopy size="large" lineHeight="dense" noMargin>
            {formatMessage({ id: 'XJM_JOBS_SEO_ROLE_SALARY_LABEL' })}
          </BodyCopy>
        </SalaryCard>
      ) : null}
      <SalaryRoleDescription noMargin>
        <FormattedMessage
          id="XJM_JOBS_SEO_ROLE_SALARY_DESCRIPTION"
          values={{
            jobRole: capitalizeFirstLetter(serps?.categoryValue || ''),
            maximumSalary: <strong>{getFormattedNumber(maximum || 0)}€</strong>,
            minimumSalary: <strong>{getFormattedNumber(minimum || 0)}€</strong>,
            averageSalary: getFormattedNumber(median || 0),
          }}
        />
      </SalaryRoleDescription>
    </Container>
  );
};
