import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';

import Companies from './serps-data/companies.json';
import Disciplines from './serps-data/disciplines.json';
import EmploymentTypes from './serps-data/employmentTypes.json';
import Skills from './serps-data/skills.json';

type StaticDataItem = {
  id: string;
  localization_key: string;
  slugs: {
    de: string;
    en: string;
    es: string;
    fr: string;
    it: string;
  };
};

type StaticDataSearchParams = {
  staticData: StaticDataItem[];
  filterFn: (element: StaticDataItem) => boolean;
  valueSelectorFn: (element: StaticDataItem) => string;
  defaultValue?: string;
};

const staticDataSearch = ({
  staticData,
  filterFn,
  valueSelectorFn,
  defaultValue,
}: StaticDataSearchParams): string => {
  defaultValue = defaultValue || '';
  const matchingObject = staticData.find(filterFn);
  return matchingObject ? valueSelectorFn(matchingObject) : defaultValue;
};

const slugToId = (staticData: StaticDataItem[], slug: string): string =>
  staticDataSearch({
    staticData,
    filterFn: (element) => Object.values(element.slugs).includes(slug),
    valueSelectorFn: (element) => element.id,
  });

const idToCanonicalSlug = (staticData: StaticDataItem[], id: string): string =>
  staticDataSearch({
    staticData,
    filterFn: (element) => element.id === id,
    valueSelectorFn: (element) => element.slugs.de,
  });

const idToLocalizedKey = (staticData: StaticDataItem[], id: string): string =>
  staticDataSearch({
    staticData,
    filterFn: (element) => element.id === id,
    valueSelectorFn: (element) => element.localization_key,
  });

const slugToLocalizedKey = (
  staticData: StaticDataItem[],
  slug: string
): string =>
  staticDataSearch({
    staticData,
    filterFn: (element) => Object.values(element.slugs).includes(slug),
    valueSelectorFn: (element) => element.localization_key,
  });

// Slug helpers
export const slugify = (value: string): string =>
  value.toLowerCase().replace(/[ /]/g, '-');
export const deslugify = (value: string): string =>
  value
    .split('-')
    .map((s) => `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`)
    .join(' ');

// Disciplines Helpers
export const disciplineSlugToId = (slug: string): string =>
  slugToId(Disciplines, slug);
export const disciplineIdToCanonicalSlug = (id: string): string =>
  idToCanonicalSlug(Disciplines, id);
export const disciplineIdToLocalizedKey = (id: string): string =>
  idToLocalizedKey(Disciplines, id);
export const disciplineSlugToLocalizedKey = (slug: string): string =>
  slugToLocalizedKey(Disciplines, slug);

// EmpoymentTypes Helpers
export const employmentTypeSlugToId = (slug: string): string =>
  slugToId(EmploymentTypes, slug);
export const employmentTypeIdToCanonicalSlug = (id: string): string =>
  idToCanonicalSlug(EmploymentTypes, id);
export const employmentTypeIdToLocalizedKey = (id: string): string =>
  idToLocalizedKey(EmploymentTypes, id);
export const employmentTypeSlugToLocalizedKey = (slug: string): string =>
  slugToLocalizedKey(EmploymentTypes, slug);

// Companies Helpers
export const companySlugToLocalizedString = (slug: string): string => {
  return (
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (Companies as Record<string, string>)[slug] ??
    `${SERP_ROUTES_IDENTIFIERS.company.toUpperCase()}-${deslugify(slug)}`
  );
};

// Skills Helpers
export const skillSlugToLocalizedString = (slug: string): string =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (Skills as Record<string, string>)[slug] ?? deslugify(slug);
