import type React from 'react';

import { Breadcrumb } from '@xing-com/crate-jobs-domain-serps-components-breadcrumb';
import { RelatedJobs } from '@xing-com/crate-jobs-domain-serps-components-related-jobs';
import { SalaryData } from '@xing-com/crate-jobs-domain-serps-components-salary-data';
import { ServicesList } from '@xing-com/crate-jobs-domain-serps-components-services-list';
import { TopCategories } from '@xing-com/crate-jobs-domain-serps-components-top-categories';
import { TopCitiesSkills } from '@xing-com/crate-jobs-domain-serps-components-top-cities-skills';
import { TopSubcategories } from '@xing-com/crate-jobs-domain-serps-components-top-subcategories';
import { ROLE } from '@xing-com/crate-jobs-domain-serps-constants';
import type { SerpsRouteParams } from '@xing-com/crate-jobs-domain-serps-helpers';

interface SerpsContainer {
  serps: SerpsRouteParams;
}

export const SerpsContainer: React.FC<SerpsContainer> = ({ serps }) => {
  const subcategories = serps.subcategory ? null : <TopSubcategories />;

  const isJobRoleSerp =
    serps.category === ROLE && serps?.subcategory === undefined;

  return (
    <>
      <Breadcrumb />
      {isJobRoleSerp ? (
        <>
          <SalaryData />
          <RelatedJobs />
          <TopCitiesSkills />
        </>
      ) : null}
      {subcategories}
      <TopCategories />
      <ServicesList />
    </>
  );
};
