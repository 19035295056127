import type React from 'react';
import styled from 'styled-components';

import { SerpsContainer } from '@xing-com/crate-jobs-domain-serps-components-container';
import { useSerpsMatch } from '@xing-com/crate-jobs-domain-serps-hooks';
import { Container as LayoutContainer } from '@xing-com/layout-primitives';
import { mediaConfined } from '@xing-com/layout-tokens';
import { spaceXXL, space4XL } from '@xing-com/tokens';

const StyledContainer = styled(LayoutContainer)`
  margin: ${space4XL} auto;
  padding: 0 20px;

  @media ${mediaConfined} {
    padding: 0 ${spaceXXL};
  }
`;

export const Container: React.FC = () => {
  const serps = useSerpsMatch();

  return serps ? (
    <StyledContainer variant="wide">
      <SerpsContainer serps={serps} />
    </StyledContainer>
  ) : null;
};
