import type React from 'react';

import { TagList } from '@xing-com/crate-jobs-domain-serps-components-tag-list';
import { CITY, SKILL } from '@xing-com/crate-jobs-domain-serps-constants';
import { capitalizeFirstLetter } from '@xing-com/crate-jobs-domain-serps-helpers';
import {
  useTopCategories,
  useSerpsMatch,
} from '@xing-com/crate-jobs-domain-serps-hooks';
import { SERP_ROUTES_IDENTIFIERS } from '@xing-com/crate-jobs-paths';

export const TopCategories: React.FC = () => {
  const serps = useSerpsMatch();
  const {
    cities,
    roles,
    employmentTypes,
    citiesNearby,
    relatedJobRolesBySkill,
    relatedSkillsByJobRole,
    topLocationsBySkill,
  } = useTopCategories();

  if (serps?.category === SKILL && serps?.subcategory === CITY) {
    return null;
  }

  const hasCitiesNearby = citiesNearby && citiesNearby.length > 0;

  const parsedRelatedSkillsByJobRole = (relatedSkillsByJobRole || []).map(
    (item) => ({
      ...item,
      url: `/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${item.seoName}`,
    })
  );

  const parsedCitiesNearby = (citiesNearby || []).map((item) => ({
    ...item,
    url: `/jobs/${SERP_ROUTES_IDENTIFIERS.city}-${item.seoName}`,
  }));

  const parsedTopLocationsBySkill = (topLocationsBySkill || []).map((item) => ({
    ...item,
    url: `/jobs/${SERP_ROUTES_IDENTIFIERS.skill}/${serps?.categoryValue}-${SERP_ROUTES_IDENTIFIERS.city}-${item.seoName}`,
  }));

  const parsedRelatedJobRolesBySkill = (relatedJobRolesBySkill || []).map(
    (item) => ({
      ...item,
      url: `/jobs/${SERP_ROUTES_IDENTIFIERS.role}-${item.seoName}`,
    })
  );

  return (
    <>
      <TagList
        headerCopyKey="XJM_SEO_TOP_LOCATIONS_BY_SKILL"
        headerCopyValues={{
          skill: capitalizeFirstLetter(serps?.categoryValue || ''),
        }}
        list={parsedTopLocationsBySkill}
      />
      <TagList
        headerCopyKey="XJM_SEO_TOP_JOBS_TITLE"
        serpsList={roles}
        showCount="hide"
        showEmptyResults="show"
      />
      {hasCitiesNearby ? (
        <TagList
          headerCopyKey="XJM_SEO_TOP_CITIES_NEARBY_TITLE"
          list={parsedCitiesNearby}
          showCount="hide"
          showEmptyResults="show"
        />
      ) : (
        <TagList
          headerCopyKey="XJM_SEO_TOP_CITIES_TITLE"
          serpsList={cities}
          showCount="hide"
          showEmptyResults="show"
        />
      )}
      {!serps ? (
        <TagList
          headerCopyKey="XJM_SEO_TOP_JOBS_TYPES_TITLE"
          serpsList={employmentTypes}
          showCount="hide"
          showEmptyResults="show"
        />
      ) : null}
      <TagList
        headerCopyKey="XJM_SEO_RELATED_JOB_TITLES_BY_SKILL"
        headerCopyValues={{
          skill: capitalizeFirstLetter(serps?.categoryValue || ''),
        }}
        list={parsedRelatedJobRolesBySkill}
        showCount="hide"
        showEmptyResults="show"
      />
      <TagList
        headerCopyKey="XJM_SEO_RELATED_SKILLS_BY_JOB_ROLE"
        list={parsedRelatedSkillsByJobRole}
        showCount="hide"
        showEmptyResults="show"
      />
    </>
  );
};
